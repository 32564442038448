<template>
  <v-card
    class="mx-auto"
  >
  <v-subheader id="fl-title">Seguidos</v-subheader>

   <v-list id="fl-list-following" class="list-following">
      <v-list-item
        class="fl-item-following"
        v-for="(data, index) in usersFollowing"
        :key="index"
      >
          <avatar
          class="fl-avatar"
          :username="data.following.name"
          background-color="#FFC107"
          :size="50"
          color="#fff">
        </avatar>
        <span>{{ data.following.name }}</span>
      </v-list-item>
    </v-list>
  </v-card>
</template>

  <script>
  import Avatar from "vue-avatar";

  export default {
    name: 'Following',
    props: {
      usersFollowing: Array
    },
    data() {
      return {
        items: [
          { icon: true, title: 'Jason Oner', avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg' },
          { title: 'Travis Howard', avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg' },
          { title: 'Ali Connors', avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg' },
          { title: 'Cindy Baker', avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg' },
        ]
      }
    },
    components: {
      Avatar
    }
  }
  </script>

  <style lang="scss" scoped>
  #fl-title{
    color: #fff;
    font-size: 1.2em;
    background-image: radial-gradient(circle at 120.71% 50%, rgb(177, 97, 167) 0px, rgb(147, 73, 154) 25%, rgb(108, 43, 136) 50%, rgb(63, 15, 119) 75%, rgb(0, 3, 108) 100%);
    margin: 10px;
    border-radius: 30px;
    width: 50%;
    height: 30px;
    text-align: center
  }
  .fl-item-following {
    display: inline-block;
    text-align: center;
    flex: none;
  }

  #fl-list{
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        display: flex !important;
        width: 100%;
  }
  #fl-list span{
    text-align:center;
  }
  .fl-avatar {
    margin: 0 auto;
  }
  div.list-following {
    margin: 4px, 4px;
    padding: 4px;
    width: 300px;
    overflow: auto;
    white-space: nowrap;
    overflow: hidden;
  }
  </style>
