import axios from "axios";
import authHeader from "./auth-header";

class ChatService {
  postUsersChat(listUsers) {
    const formData = new FormData();
    formData.append("listUsersId", listUsers);
    return axios
      .post("users-chatrooms", formData, {
        headers: { ...authHeader(), "Content-Type": "multipart/form-data" }
      })
      .then(response => {
        return response.data;
      });
  }
}

export default new ChatService();
