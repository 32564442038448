<template>
    <div>
        <!-- <v-navigation-drawer v-model="drawer" absolute temporary app>
      <v-list nav shaped>
        <v-subheader>CATEGORÍAS</v-subheader>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <router-link
            v-for="data in categories"
            :key="data.id_category"
            :to="{
              path: 'video-by-category',
              query: { idCategory: data.id_category }
            }"
          >
            <v-list-item>
              <v-list-item-icon class="mr-1">
                <v-icon>fa-angle-right</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-text="data.name_category"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer> -->

        <!-- Barra navegation -->
        <v-bottom-navigation
            v-model="value"
            :color="btnColorIconNavigation"
            height="45"
            app
            fixed
        >
            <v-btn to="/" style="color:none">
                <!--<span>Inicio</span>-->

                <v-icon size="18">fa-home</v-icon>
            </v-btn>

            <!-- Menú de categorias HIDDEN -->
            <!-- <v-btn @click.stop="drawer = !drawer">
        <v-icon>fa-bars</v-icon>
      </v-btn> -->

            <v-btn to="/favorite">
                <v-icon size="18">fa-play</v-icon>
            </v-btn>

            <v-btn to="/upload-video">
                <v-icon size="18">fa-plus-square</v-icon>
            </v-btn>

            <v-btn to="/list-games">
                <v-icon size="18">fa-gamepad</v-icon>
            </v-btn>

            <v-btn to="/profile">
                <!--<span>Perfil</span>-->

                <v-icon size="18">fa-user</v-icon>
            </v-btn>
        </v-bottom-navigation>
    </div>
</template>

<script>
export default {
    name: "MenuNavegation",
    data() {
        return {
            drawer: false,
            group: null,
            value: 0,
            btnColorIconNavigation: "#6200ea"
        };
    },
    watch: {
        group() {
            this.drawer = false;
        }
    },
    computed: {
        // categories() {
        //   return this.$store.state.category.categories;
        // }
    },
    mounted() {
        // this.$store.dispatch("category/getCategories");
    },
    methods: {
        // videoByCategory(idCategory) {
        //   this.$router.push({ name: "user", params: { idCategory: idCategory } });
        // }
    }
};
</script>

<style lang="scss">
@import "../scss/_variables.scss";

.v-list-item__title {
    font-size: $__font-size-labels;
}

.v-toolbar--prominent .v-toolbar__title {
    font-size: $__font-size-title !important;
}
</style>
