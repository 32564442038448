<template>
  <div>
    <v-row>
      <v-col cols="12 ml-3 mb-4">
        <h3>Chats</h3>
      </v-col>
    </v-row>
    <router-link
      v-for="(room, index) in listRooms"
      :key="index"
      :to="{
        path: '/chats/message',
        query: { id: room.uuid }
      }"
    >
      <v-list two-line subheader>
        <v-container>
          <v-row>
            <v-col class="pa-0" cols="12">
              <v-list-item class="pa-0">
                <v-list-item-avatar>
                  <avatar
                    :username="room.name"
                    background-color="#FFC107"
                    :size="45"
                    color="#fff"
                  >
                  </avatar>
                </v-list-item-avatar>
                <p>
                  <b>{{ room.name }}</b
                  ><br />
                  {{ room.lastMessage }}
                </p>
              </v-list-item>
            </v-col>
          </v-row>
        </v-container>
      </v-list>
    </router-link>
  </div>
</template>

<script>
import { db } from "../firebase";
import ChatService from "../services/chat.service";
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  limit
} from "firebase/firestore";

import Avatar from "vue-avatar";

export default {
  name: "ListChatRooms",
  data() {
    return {
      listRooms: [],
      timeExpire: 86400000
    };
  },
  props: {
    user: Object
  },
  created() {
    this.listChatRooms();
  },
  methods: {
    clearLocalStorage: function(key) {
      let val = localStorage.getItem(key);
      if (!val) {
        return val;
      }
      val = JSON.parse(val);
      if (Date.now() - val.time > val.expire) {
        localStorage.removeItem(key);
        return null;
      }
    },
    reduceMessage: function(listResults, listUsers) {
      return listResults.reduce((acc, curr) => {
        const uuid = curr.uuid;
        const isNameAvl = listUsers.find(elem => elem.uuid.includes(uuid));

        isNameAvl && acc.push({ ...curr, ...isNameAvl });
        !isNameAvl && acc.push(curr);
        return acc;
      }, []);
    },
    listChatRooms: function() {
      // Call real time
      const q = query(
        collection(db, "groups"),
        where("members", "array-contains", this.user.uuid),
        orderBy("createdAt", "desc")
        // limit(20)
      );

      var listUsers =
        localStorage.getItem("chatUsersList") !== null
          ? JSON.parse(localStorage.getItem("chatUsersList")).data
          : [];

      const unsubscribe = onSnapshot(q, querySnapshot => {
        var listResults = [];
        var listMembers = [];

        querySnapshot.forEach(doc => {
          var data = doc.data();
          var members = data.members.filter(member => this.user.uuid != member);

          listMembers.push(members[0]);
          data.uuid = members[0];
          listResults.push(data);
        });

        var countListMembers = listMembers.length;
        var countListUsers = listUsers.length;

        if (countListMembers && countListUsers != countListMembers) {
          ChatService.postUsersChat(listMembers).then(response => {
            listUsers = response.data;
            let obj = {
              data: listUsers,
              time: Date.now(),
              expire: this.timeExpire
            };
            localStorage.setItem("chatUsersList", JSON.stringify(obj));

            this.reduceMessage(listResults, listUsers);

            const mergedUsers = this.reduceMessage(listResults, listUsers);

            this.listRooms = mergedUsers;
          });
        } else {
          const mergedUsers = this.reduceMessage(listResults, listUsers);
          this.listRooms = mergedUsers;
        }

        this.clearLocalStorage("chatUsersList");
      });
    }
  },
  components: {
    Avatar
  }
};
</script>

<style lang="scss" scope></style>
