<template>
    <swiper class="swiper" :options="swiperOption" ref="mySwiper">
        <swiper-slide v-for="(data, index) in contentVideo" :key="index">
            <VideoPlayerMobile
                :video="data.path_video"
                :like="data.like_video"
                :saveVideo="data.save_video"
                :followerUser="data.follower_user"
                :key="index"
                :keyIndex="index"
                :videoId="data.id_video"
                :hashtags="data.hashtags"
                :categories="data.categories"
                :userVideo="data.user"
            />
        </swiper-slide>
    </swiper>
</template>

<script>
import VideoPlayerMobile from "../components/VideoPlayerMobile";

export default {
    name: "ListVideoMobile",
    props: {
        contentVideo: Array
    },
    data() {
        return {
            lastPage: Number,
            swiperOption: {
                direction: "vertical",
                centeredSlides: true,
                // effect: "fade",
                onSlideChangeEnd: this.changeSwiperIndex
                // slidesPerView: 1,
                // spaceBetween: 5
            }
        };
    },
    components: {
        VideoPlayerMobile
    },
    mounted() {
        var videoActiveContainer = document
            .querySelector(".swiper-slide-active")
            .querySelector("video");
        const promise = videoActiveContainer.play();
        if (promise !== undefined) {
            promise
                .then(() => {
                    // Autoplay started
                })
                .catch(error => {
                    // Autoplay was prevented.
                    videoActiveContainer.muted = true;
                    videoActiveContainer.play();
                });
        }
    },
    methods: {
        changeSwiperIndex() {
            var activeIndex = this.$refs.mySwiper.$swiper.activeIndex;
            // console.log(this.$refs.mySwiper.$swiper);
            // if (activeIndex !== 0) {
            // document
            //     .querySelector(".swiper-slide-prev")
            //     .querySelector("video")
            //     .pause();

            var videoList = document.getElementsByTagName("video");
            for (var i = 0; i < videoList.length; i++) {
                videoList[i].currentTime = 1;
                videoList[i].pause();
            }

            // }
            // var slidesCount = document.getElementsByClassName("swiper-slide").length;
            // if (activeIndex !== slidesCount - 1) {

            // var videoNextContainer = document.querySelector(
            //     ".swiper-slide-next"
            // );
            // if (videoNextContainer != null) {
            //     videoNextContainer.querySelector("video").pause();
            // }

            // }
            var videoActiveContainer = document.querySelector(
                ".swiper-slide-active"
            );
            var videoActive = videoActiveContainer.querySelector("video");
            // videoActive.currentTime = 1;
            const promise = videoActive.play();
            if (promise !== undefined) {
                promise
                    .then(() => {
                        // Autoplay started
                    })
                    .catch(error => {
                        // Autoplay was prevented.
                        videoActive.muted = true;
                        videoActive.play();
                    });
            }

            var slidesCount = document.getElementsByClassName("swiper-slide")
                .length;
            if (activeIndex === slidesCount - 2) {
                // if (this.lastPage != this.nexPage) {
                //     this.lastPage = this.nexPage;
                //     this.loadMoreVideo(this.nexPage);
                // }
                this.loadMoreVideo();
            }
        },
        loadMoreVideo(nexPage = null) {
            this.$store.dispatch("swiperVideo/getMoreVideos");
            // this.$store.dispatch("swiperVideo/getMoreVideos", nexPage);
            // this.amountVideos += 1;
        }
    }
};
</script>
