<template>
    <v-container>
        <v-row align="center" justify="center">
            <v-col class="pa-0" xs="6" sm="6" md="5" lg="4">
                <v-card flat>
                    <div v-if="contentVideo !== null">
                        <v-card-text align="center" justify="center">
                            <h3>Aquí podrá ver sus videos guardados</h3>
                        </v-card-text>
                    </div>

                    <ListVideoBookmark
                        :contentVideo="contentVideo"
                        v-if="isLoading"
                    />

                    <v-skeleton-loader
                        v-else
                        :loading="!isLoading"
                        width="100%"
                        type="image, list-item-avatar-three-line, list-item-avatar-three-line"
                    ></v-skeleton-loader>
                </v-card>
            </v-col>
        </v-row>
        <MenuNavegation />
    </v-container>
</template>

<script>
import ListVideoBookmark from "../components/ListVideoBookmark";
import MenuNavegation from "../components/MenuNavegation";

export default {
    name: "Bookmark",
    data: () => ({
        value: 3,
        btnColorIconNavigation: "#6200ea"
    }),
    computed: {
        contentVideo() {
            return this.$store.state.swiperVideo.videos;
        },
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
        isLoading() {
            return this.$store.state.swiperVideo.status.loading;
        }
    },
    created() {
        // if (!this.loggedIn) {
        //   this.$router.push('/login')
        // }
    },
    mounted() {
        this.$store.dispatch("swiperVideo/getVideosBookmark");
    },
    components: {
        ListVideoBookmark,
        MenuNavegation
    }
};
</script>

<style>
body {
    background-color: rgb(244, 244, 244) !important;
}
</style>
