<template>
  <div>
    <v-container>
      <v-row class="top-hashtags">
        <v-col class="pa-0" xs="12" sm="12" md="12" lg="12" xl="10">
          <v-card class="mx-auto overflow-hidden" flat height="100%">
            <v-app-bar
              dark
              height="110"
              style="background-image: radial-gradient(circle at 120.71% 50%, #b161a7 0, #93499a 25%, #6c2b88 50%, #3f0f77 75%, #00036c 100%);"
            >
              <v-toolbar-title v-if="loggedIn"
                >Hola, {{ user.user.name }}</v-toolbar-title
              >
              <v-spacer></v-spacer>
              <v-btn
                icon
                class="pr-4"
                v-if="user"
                @click.stop="drawer = !drawer"
              >
                <avatar
                  v-if="loggedIn"
                  :username="user.user.name"
                  background-color="#FFC107"
                  :size="57"
                  color="#fff"
                >
                </avatar>
              </v-btn>
            </v-app-bar>
            <v-list>
              <v-list-item-group>
                <v-list-item
                  v-for="(itemHashtag, index) in topHashtags"
                  :key="index"
                >
                  <v-list-item-icon>
                    <v-avatar color="indigo" size="30">
                      <span class="white--text text-h6">#</span>
                    </v-avatar>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="itemHashtag.hashtag"
                    ></v-list-item-title>
                    <v-row class="container-scroll-videos">
                      <v-col
                        v-for="(data, index) in itemHashtag.videos"
                        :key="index"
                        cols="4"
                        sm="4"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <video
                          :src="data.path_video"
                          preload="true"
                          class="video-icon"
                          @click="playVideo(data.path_video)"
                        />
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog
        v-model="dialog"
        v-if="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        class="video_dialog"
      >
        <v-card>
          <v-toolbar
            dark
            style="background-image: radial-gradient(circle at 120.71% 50%, #b161a7 0, #93499a 25%, #6c2b88 50%, #3f0f77 75%, #00036c 100%);"
          >
            <v-spacer></v-spacer>
            <v-btn icon dark @click="dialog = false">
              <v-icon>fa-xmark</v-icon>
            </v-btn>
          </v-toolbar>

          <video :src="path_video" preload="true" autoplay />
        </v-card>
      </v-dialog>

      <MenuNavegation v-if="isMobile" />
    </v-container>
  </div>
</template>

<script>
import MenuNavegation from "../components/MenuNavegation";
import { isMobile } from "mobile-device-detect";
import Avatar from "vue-avatar";

export default {
  name: "TopHashtags",
  data: () => ({
    path_video: null,
    dialog: false
    // drawer: false,
    // group: null,
    // value: 0,
    // btnColorIconNavigation: '#6200ea'
  }),
  computed: {
    isMobile() {
      return isMobile;
    },
    topHashtags() {
      return this.$store.state.hashtagTop.hashtags;
    },
    isLoading() {
      return this.$store.state.hashtagTop.status.loading;
    },
    user() {
      return this.$store.state.auth.user;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    this.$store.dispatch("hashtagTop/getTopHashtags");
  },
  methods: {
    playVideo: function(path_video) {
      this.dialog = true;
      this.path_video = path_video;
      var videoContainer = document.querySelector(".video_dialog");
      if (videoContainer != null) {
        videoContainer.querySelector("video").play();
      }
    }
  },
  components: {
    MenuNavegation,
    Avatar
  }
};
</script>

<style>
body {
  background-color: rgb(244, 244, 244) !important;
}

.isLoading {
  background: rgb(177, 97, 167);
  background: linear-gradient(
    180deg,
    rgba(177, 97, 167, 1) 0%,
    rgba(147, 73, 154, 1) 25%,
    rgba(108, 43, 136, 1) 50%,
    rgba(63, 15, 119, 1) 75%,
    rgba(0, 3, 108, 1) 100%
  );
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.top-hashtags .v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 10px !important;
}

.top-hashtags .v-list-item__content {
  text-transform: capitalize;
}

.container-scroll-videos {
  display: flex;
  overflow-x: auto;
}

.container-scroll-videos video {
  margin-right: 15px;
}

a:link {
  text-decoration: none;
}

video {
  width: 100%;
  height: auto;
  object-fit: fill;
  top: 0;
  left: 0;
}

/* .top-hashtags .v-list-item {
    border-bottom: 2px solid #dadbdf;
} */
</style>
