<template>
    <v-container class="pt-7">
        <ListChatRooms :user="user" />
        <div class="list-users-chat">
            <v-dialog
                v-model="dialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
                scrollable
                :content-class="'scrollComments'"
            >
                <v-card tile>
                    <v-btn
                        icon
                        dark
                        right
                        absolute
                        style="z-index: 9999"
                        @click="closeDialog"
                    >
                        <v-icon color="black">fa-times</v-icon>
                    </v-btn>
                    <v-container class="">
                        <v-row>
                            <v-col cols="12">
                                <h3>Mi lista de contactos</h3>
                            </v-col>
                        </v-row>
                    </v-container>
                    <router-link
                        v-for="(userContact, index) in ListUsers"
                        :to="{
                            path: '/chats/message',
                            query: { id: userContact.following.uuid }
                        }"
                        :key="index"
                    >
                        <v-container>
                            <v-list two-line subheader>
                                <v-row>
                                    <v-col class="pa-0" cols="12">
                                        <v-list-item class="pa-0">
                                            <v-list-item-avatar>
                                                <avatar
                                                    :username="
                                                        userContact.following
                                                            .name
                                                    "
                                                    background-color="#FFC107"
                                                    :size="45"
                                                    color="#fff"
                                                >
                                                </avatar>
                                            </v-list-item-avatar>
                                            <span class="name-user">
                                                <b>{{
                                                    userContact.following.name
                                                        | capitalising
                                                }}</b>
                                            </span>
                                        </v-list-item>
                                    </v-col>
                                </v-row>
                            </v-list>
                            <div style="flex: 1 1 auto"></div>
                        </v-container>
                    </router-link>
                </v-card>
            </v-dialog>
        </div>
        <v-btn
            icon
            fab
            right
            absolute
            class="icon-message"
            @click="openDialogContacts()"
        >
            <v-icon size="40">fa-comments</v-icon>
        </v-btn>
        <MenuNavegation />
    </v-container>
</template>

<script>
import Comment from "../models/comment";
import Avatar from "vue-avatar";
import ListChatRooms from "../components/ListChatRooms";
import MenuNavegation from "../components/MenuNavegation";

export default {
    name: "ChatRooms",
    data: () => ({
        btnColorIconNavigation: "#6200ea",
        listRooms: [],
        dialog: false
    }),
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
        user() {
            return this.$store.state.auth.user.user;
        },
        ListUsers() {
            return this.$store.state.usersFollowing.followings;
        }
    },
    filters: {
        capitalising: function(data) {
            var capitalized = [];
            data.split(" ").forEach(word => {
                capitalized.push(
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                );
            });
            return capitalized.join(" ");
        }
    },
    created() {
        if (!this.loggedIn) {
            this.$router.push("/login");
        }
        this.$store.dispatch("usersFollowing/getUserFollowings");
    },
    methods: {
        closeDialog: function() {
            this.dialog = false;
        },
        openDialogContacts: function() {
            this.dialog = true;
        }
    },
    components: {
        Avatar,
        ListChatRooms,
        MenuNavegation
    }
};
</script>

<style lang="scss" scope>
.icon-message {
    color: rgb(98, 0, 234) !important;
    z-index: 3;
    position: absolute;
    bottom: 7%;
}
a:link {
    text-decoration: none;
}
</style>
