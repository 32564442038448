<template>
    <v-container>
        <v-row>
            <v-col class="pa-0" xs="12" sm="12" md="12" lg="12" xl="10">
                <v-card class="mx-auto overflow-hidden" flat height="100%">
                    <v-app-bar
                        dark
                        height="110"
                        style="background-image: radial-gradient(circle at 120.71% 50%, #b161a7 0, #93499a 25%, #6c2b88 50%, #3f0f77 75%, #00036c 100%);"
                    >
                        <v-toolbar-title v-if="loggedIn"
                            >Hola, {{ user.user.name }}</v-toolbar-title
                        >

                        <v-spacer></v-spacer>

                        <v-btn
                            v-if="loggedIn"
                            icon
                            class="pr-4"
                            @click.stop="drawer = !drawer"
                        >
                            <avatar
                                :username="user.user.name"
                                background-color="#FFC107"
                                :size="57"
                                color="#fff"
                            >
                            </avatar>
                        </v-btn>
                    </v-app-bar>

                    <v-navigation-drawer
                        v-model="drawer"
                        fixed
                        bottom
                        temporary
                    >
                        <v-list>
                            <v-list-item-group v-model="group">
                                <router-link to="/favorite">
                                    <v-list-item>
                                        <v-list-item-title>
                                            <span>Videos favoritos</span>
                                        </v-list-item-title>
                                    </v-list-item>
                                </router-link>

                                <router-link to="/top-hashtags">
                                    <v-list-item>
                                        <v-list-item-title>
                                            <span>Hashtags destacados</span>
                                        </v-list-item-title>
                                    </v-list-item>
                                </router-link>

                                <v-list-item @click="logout">
                                    <v-list-item-title>Salir</v-list-item-title>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-navigation-drawer>

                    <Following :usersFollowing="usersFollowing" />

                    <HashtagTrendings :hashtagTrandings="hashtagsTrending" />

                    <v-card-text>
                        <v-toolbar-title class="font-weight-bold pb-5"
                            >Mis videos favoritos</v-toolbar-title
                        >
                        <v-row v-if="!isLoading">
                            <v-col>
                                <v-skeleton-loader
                                    :loading="isLoading"
                                    width="100%"
                                    type="image, list-item-three-line"
                                ></v-skeleton-loader>
                            </v-col>
                            <v-col>
                                <v-skeleton-loader
                                    :loading="isLoading"
                                    width="100%"
                                    type="image, list-item-three-line"
                                ></v-skeleton-loader>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col
                                cols="4"
                                sm="4"
                                md="4"
                                lg="4"
                                xl="4"
                                v-for="(data, index) in contentVideo"
                                :key="index"
                            >
                                <video
                                    :key="index"
                                    :src="data.path_video"
                                    preload="true"
                                    class="video-icon"
                                    @click="playVideo(index)"
                                />

                                <v-dialog
                                    v-model="dialog[index]"
                                    v-if="dialog[index]"
                                    fullscreen
                                    hide-overlay
                                    transition="dialog-bottom-transition"
                                    :class="'video_dialog_' + index"
                                >
                                    <v-card>
                                        <v-toolbar
                                            dark
                                            style="background-image: radial-gradient(circle at 120.71% 50%, #b161a7 0, #93499a 25%, #6c2b88 50%, #3f0f77 75%, #00036c 100%);"
                                        >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                icon
                                                dark
                                                @click="dialog = []"
                                            >
                                                <v-icon>fa-xmark</v-icon>
                                            </v-btn>
                                        </v-toolbar>

                                        <video
                                            :key="index"
                                            :src="data.path_video"
                                            preload="metadata"
                                            autoplay
                                        />
                                    </v-card>
                                </v-dialog>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <MenuNavegation />
    </v-container>
</template>

<script>
import { isMobile } from "mobile-device-detect";
import MenuNavegation from "../components/MenuNavegation";
import HashtagTrendings from "../components/HashtagTrendings";
import Following from "../components/Following";
import Avatar from "vue-avatar";

export default {
    name: "Favorite",
    data: () => ({
        drawer: false,
        group: null,
        // dialog
        dialog: {},
        notifications: false,
        sound: true,
        widgets: false
    }),
    created() {
        if (!this.loggedIn) {
            this.$router.push("/login");
        } else {
            this.$store.dispatch(
                "hashtagTrendingFavorite/getHashtagTrendingFavorite"
            );
            this.$store.dispatch("usersFollowing/getUserFollowings");
            this.$store.dispatch("videosByUser/getVideosFavorites");
        }
    },
    computed: {
        isMobile() {
            return isMobile;
        },
        user() {
            return this.$store.state.auth.user;
        },
        contentVideo() {
            return this.$store.state.videosByUser.videos;
        },
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
        isLoading() {
            return this.$store.state.videosByUser.status.loading;
        },
        hashtagsTrending() {
            return this.$store.state.hashtagTrendingFavorite.hashtags;
        },
        isLoadingHashtagsTranding() {
            return this.$store.state.hashtagTrendingFavorite.status.loading;
        },
        usersFollowing() {
            return this.$store.state.usersFollowing.followings;
        },
        isLoadingUsersFollowing() {
            return this.$store.state.usersFollowing.status.loading;
        }
    },
    watch: {
        group() {
            this.drawer = false;
        }
    },
    methods: {
        logout: function() {
            this.$store.dispatch("auth/LogoutURI").then(() => {
                this.$router.push("/login");
            });
        },
        playVideo: function(index) {
            this.$set(this.dialog, index, true);

            var videoContainer = document.querySelector(
                ".video_dialog_" + index
            );
            if (videoContainer != null) {
                videoContainer.querySelector("video").play();
            }
        }
    },
    components: {
        MenuNavegation,
        HashtagTrendings,
        Following,
        Avatar
    }
};
</script>

<style lang="scss" scope>
@import "../scss/_variables.scss";

.v-list-item__title {
    font-size: $__font-size-labels;
}

v-toolbar__title {
    font-size: $__font-size-labels;
}
a:link {
    text-decoration: none;
}
video {
    width: 100%;
    height: auto;
    object-fit: fill;
    top: 0;
    left: 0;
}
.v-navigation-drawer {
    height: 36% !important;
}
</style>
