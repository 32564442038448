<template>
    <v-container>
        <v-list
            v-for="(message, index) in messages"
            :key="index"
            two-line
            subheader
        >
            <v-container>
                <v-row>
                    <v-col class="pa-0" cols="12">
                        <v-list-item class="pa-0">
                            <v-list-item-avatar>
                                <avatar
                                    :username="message.name"
                                    background-color="#FFC107"
                                    :size="45"
                                    color="#fff"
                                >
                                </avatar>
                            </v-list-item-avatar>
                            <p>
                                <b>{{ message.name | capitalising }}</b
                                ><br />
                                {{ message.messageText }}
                            </p>
                        </v-list-item>
                    </v-col>
                </v-row>
            </v-container>
        </v-list>

        <!-- <v-container> -->
        <v-row align="center" justify="center">
            <v-col cols="12">
                <v-form name="form" @submit.prevent="handleMessage()">
                    <v-container class="comments_input_video">
                        <v-row align="center">
                            <v-col cols="10" sm="10">
                                <v-text-field
                                    v-model="comment.comment"
                                    label="Enviar mensaje..."
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="2" sm="2">
                                <button class="btn btn-primary">
                                    <span
                                        class="spinner-border spinner-border-sm"
                                    ></span>
                                    <v-icon>fa-paper-plane</v-icon>
                                </button>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Comment from "../models/comment";
import ChatService from "../services/chat.service";
import { db } from "../firebase";
import {
    collection,
    addDoc,
    query,
    where,
    setDoc,
    onSnapshot,
    orderBy,
    limit,
    doc,
    Timestamp
} from "firebase/firestore";
import Avatar from "vue-avatar";

export default {
    name: "VideosByHashtag",
    data: () => ({
        messages: [],
        listMembers: [],
        comment: new Comment("", ""),
        timeExpire: 86400000
    }),
    computed: {
        user() {
            return this.$store.state.auth.user.user;
        },
        uuidUser() {
            return this.$route.query.id;
        },
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        }
    },
    created() {
        this.loadChatMessage();
    },
    filters: {
        capitalising: function(data) {
            var capitalized = [];
            data.split(" ").forEach(word => {
                capitalized.push(
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                );
            });
            return capitalized.join(" ");
        }
    },
    methods: {
        handleMessage: async function() {
            var uuid =
                this.user.uuid > this.uuidUser
                    ? this.user.uuid + this.uuidUser
                    : this.uuidUser + this.user.uuid;

            addDoc(collection(db, "messages"), {
                chatGroupId: uuid,
                createdAt: Timestamp.fromDate(new Date()),
                read: false,
                members: [this.user.uuid, this.uuidUser],
                messageText: this.comment.comment,
                userId: this.user.uuid
            });

            var dataGroup = {
                createdAt: Timestamp.fromDate(new Date()),
                read: false,
                members: [this.user.uuid, this.uuidUser],
                lastMessage: this.comment.comment
            };
            setDoc(doc(db, "groups", uuid), dataGroup, {
                merge: true
            });

            this.comment.comment = "";
            this.toBottom();
        },
        toBottom() {
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: "smooth"
            });
        },
        clearLocalStorage: function(key) {
            let val = localStorage.getItem(key);
            if (!val) {
                return val;
            }
            val = JSON.parse(val);
            if (Date.now() - val.time > val.expire) {
                localStorage.removeItem(key);
                return null;
            }
        },
        reduceMessage: function(listMessages, listUsers) {
            return listMessages.reduce((acc, curr) => {
                const uuid = curr.userId;
                const isNameAvl = listUsers.find(elem =>
                    elem.uuid.includes(uuid)
                );

                isNameAvl && acc.push({ ...curr, ...isNameAvl });
                !isNameAvl && acc.push(curr);
                return acc;
            }, []);
        },
        loadChatMessage: function() {
            var uuid =
                this.user.uuid > this.uuidUser
                    ? this.user.uuid + this.uuidUser
                    : this.uuidUser + this.user.uuid;
            const q = query(
                collection(db, "messages"),
                where("chatGroupId", "==", uuid),
                orderBy("createdAt", "asc"),
                limit(70)
            );

            var listUsers =
                localStorage.getItem(uuid) !== null
                    ? JSON.parse(localStorage.getItem(uuid)).data
                    : [];
            onSnapshot(q, querySnapshot => {
                var listMessages = [];
                var listMembers = [];

                querySnapshot.forEach(doc => {
                    var data = doc.data();

                    listMembers = data.members;
                    listMessages.push(data);
                });

                var countListMembers = listMembers.length;
                var countListUsers = listUsers.length;

                if (countListMembers && countListUsers != countListMembers) {
                    ChatService.postUsersChat(listMembers).then(response => {
                        listUsers = response.data;
                        let obj = {
                            data: listUsers,
                            time: Date.now(),
                            expire: this.timeExpire
                        };
                        localStorage.setItem(uuid, JSON.stringify(obj));

                        const mergedUsers = this.reduceMessage(
                            listMessages,
                            listUsers
                        );
                        this.messages = mergedUsers;
                    });
                } else {
                    const mergedUsers = this.reduceMessage(
                        listMessages,
                        listUsers
                    );
                    this.messages = mergedUsers;
                }

                this.clearLocalStorage(uuid);
            });
        }
    },
    components: { Avatar }
};
</script>

<style lang="scss" scoped>
.comment {
    z-index: 3;
    position: absolute;
    bottom: 21%;
}

.content-comments {
    margin-bottom: 12%;
}

.comments_input_video {
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: #fff;
    padding-top: 2px !important;
    padding-bottom: 0px !important;
}
</style>
