<template>
    <v-container fluid>
        <v-row>
            <v-col class="pa-0" xs="12" sm="12" md="12" lg="12" xl="10">
                <v-app-bar
                    dark
                    height="110"
                    style="background-image: radial-gradient(circle at 120.71% 50%, #b161a7 0, #93499a 25%, #6c2b88 50%, #3f0f77 75%, #00036c 100%);"
                >
                    <h2>Ingresa</h2>
                </v-app-bar>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="mt-5" xs="12" sm="12" md="12" lg="12" xl="10">
                <v-layout justify-center>
                    <v-flex xs12 sm8 md6>
                        <v-alert
                            v-if="messageLoginError"
                            border="right"
                            colored-border
                            type="error"
                            elevation="2"
                        >
                            {{ messageLoginError }}
                        </v-alert>
                        <v-form
                            id="check-login-form"
                            @submit.prevent="handleLogin"
                        >
                            <v-text-field
                                v-model="user.phone"
                                prepend-icon="fa-user-alt"
                                name="phone"
                                label="Teléfono"
                                type="text"
                            ></v-text-field>
                            <v-text-field
                                id="password"
                                v-model="user.password"
                                prepend-icon="fa-lock"
                                name="password"
                                label="Contraseña"
                                type="password"
                            ></v-text-field>
                        </v-form>
                        <v-card-actions class="d-flex justify-center">
                            <v-btn
                                type="submit"
                                color="primary"
                                form="check-login-form"
                                >Iniciar sesión</v-btn
                            >
                            <v-btn
                                color="default"
                                form="check-login-form"
                                to="/"
                                >Cancelar</v-btn
                            >
                        </v-card-actions>

                        <router-link to="/register">
                            <v-list-item>
                                <v-list-item-title>
                                    <span
                                        >¿No tienes cuenta? Regístrate
                                        aquí</span
                                    >
                                </v-list-item-title>
                            </v-list-item>
                        </router-link>

                        <router-link to="/register">
                            <v-list-item>
                                <v-list-item-title>
                                    <span>¿Olvidaste tu contraseña?</span>
                                </v-list-item-title>
                            </v-list-item>
                        </router-link>
                    </v-flex>
                </v-layout>
            </v-col>
        </v-row>
        <MenuNavegation v-if="isMobile" />
    </v-container>
</template>

<script>
import User from "../models/user";
import MenuNavegation from "../components/MenuNavegation";
import { isMobile } from "mobile-device-detect";

export default {
    name: "Login",
    data() {
        return {
            user: new User(),
            loading: false,
            message: ""
        };
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
        messageLoginError() {
            return this.$store.state.auth.messageLoginError;
        },
        isMobile() {
            return isMobile;
        }
    },
    created() {
        if (this.loggedIn) {
            this.$router.push("/");
        }
    },
    methods: {
        handleLogin() {
            this.loading = true;
            // this.$validator.validateAll().then(isValid => {
            //   if (!isValid) {
            //     this.loading = false
            //     return
            //   }

            // console.log(this.user);

            if (this.user.phone && this.user.password) {
                this.$store.dispatch("auth/login", this.user).then(
                    () => {
                        this.$router.push("/");
                    },
                    error => {
                        this.loading = false;
                        this.message =
                            (error.response && error.response.data) ||
                            error.message ||
                            error.toString();
                    }
                );
            }
        }
    },
    components: {
        MenuNavegation
    }
};
</script>

<style scoped>
label {
    display: block;
    margin-top: 10px;
}

.card-container.card {
    max-width: 350px !important;
    padding: 40px 40px;
}

.card {
    background-color: #f7f7f7;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
</style>
