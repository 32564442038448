<template>
    <v-container>
        <v-row align="center" justify="center">
            <v-col class="pa-0" sm="6" md="5" lg="4">
                <!--<v-col class="pa-0" xs="6" sm="6" md="5" lg="4">-->
                <v-card flat>
                    <ListVideoMobile
                        v-if="isLoading"
                        :contentVideo="contentVideo"
                    />

                    <v-skeleton-loader
                        v-if="!isLoading"
                        :loading="!isLoading"
                        width="100%"
                        type="image, list-item-avatar-three-line, list-item-avatar-three-line"
                    ></v-skeleton-loader>
                </v-card>
            </v-col>
        </v-row>
        <MenuNavegation />
    </v-container>
</template>

<script>
import ListVideoMobile from "../components/ListVideoMobile";
import MenuNavegation from "../components/MenuNavegation";

export default {
    name: "VideosByHashtag",
    data: () => ({
        // drawer: false,
        // group: null
    }),
    computed: {
        user() {
            return this.$store.state.auth.user;
        },
        contentVideo() {
            return this.$store.state.swiperVideo.videos;
        },
        isLoading() {
            return this.$store.state.swiperVideo.status.loading;
        },
        hashtag() {
            return this.$route.query.hashtag;
        },
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        }
    },
    created() {
        // console.log(this.$route.query.idCategory)
        // if (!this.loggedIn) {
        //   this.$router.push('/login')
        // }
        this.$store.dispatch("swiperVideo/getVideosHashtags", this.hashtag);
    },
    watch: {
        // group () {
        //   this.drawer = false
        // },
        // '$route.query.idCategory': function (idCategory) {
        //   this.$forceUpdate()
        // }
    },
    methods: {
        async logout() {
            await this.$store.dispatch("auth/LogOut");
            this.$router.push("/login");
        }
    },
    components: {
        ListVideoMobile,
        MenuNavegation
    }
};
</script>
