<template>
    <v-container>
        <v-row>
            <v-col class="pa-0" xs="12" sm="12" md="12" lg="12" xl="10">
                <v-card class="mx-auto overflow-hidden" flat height="100%">
                    <v-app-bar
                        dark
                        height="110"
                        style="background-image: radial-gradient(circle at 120.71% 50%, #b161a7 0, #93499a 25%, #6c2b88 50%, #3f0f77 75%, #00036c 100%);"
                    >
                        <v-toolbar-title v-if="loggedIn"
                            >Hola, {{ user.name }}</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            class="pr-4"
                            v-if="user"
                            @click.stop="drawer = !drawer"
                        >
                            <avatar
                                v-if="loggedIn"
                                :username="user.name"
                                background-color="#FFC107"
                                :size="57"
                                color="#fff"
                            >
                            </avatar>
                        </v-btn>
                    </v-app-bar>

                    <v-navigation-drawer
                        v-model="drawer"
                        fixed
                        bottom
                        temporary
                    >
                        <v-list>
                            <v-list-item-group v-model="group">
                                <router-link to="/favorite">
                                    <v-list-item>
                                        <v-list-item-title>
                                            <span>Videos favoritos</span>
                                        </v-list-item-title>
                                    </v-list-item>
                                </router-link>
                                <router-link to="/edit-user">
                                    <v-list-item>
                                        <v-list-item-title>
                                            <span>Editar mis datos</span>
                                        </v-list-item-title>
                                    </v-list-item>
                                </router-link>
                                <router-link to="/chats">
                                    <v-list-item>
                                        <v-list-item-title>
                                            <span>Mis Chats</span>
                                        </v-list-item-title>
                                    </v-list-item>
                                </router-link>

                                <router-link to="/top-hashtags">
                                    <v-list-item>
                                        <v-list-item-title>
                                            <span>Hashtags destacados</span>
                                        </v-list-item-title>
                                    </v-list-item>
                                </router-link>

                                <v-list-item @click="logout">
                                    <v-list-item-title>Salir</v-list-item-title>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-navigation-drawer>

                    <v-card-text>
                        <v-toolbar-title class="font-weight-bold pb-5"
                            >Mis videos subidos</v-toolbar-title
                        >

                        <v-row>
                            <v-col
                                cols="4"
                                sm="4"
                                md="4"
                                lg="4"
                                xl="4"
                                v-for="(data, index) in contentVideosByUser"
                                :key="index"
                            >
                                <video
                                    preload="metadata"
                                    :key="index"
                                    :src="data.path_video"
                                />
                            </v-col>
                        </v-row>
                        <v-row v-if="!isLoadingVideosByUser">
                            <v-col>
                                <v-skeleton-loader
                                    width="100%"
                                    type="image, list-item-three-line"
                                ></v-skeleton-loader>
                            </v-col>
                            <v-col>
                                <v-skeleton-loader
                                    width="100%"
                                    type="image, list-item-three-line"
                                ></v-skeleton-loader>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <MenuNavegation />
    </v-container>
</template>

<script>
import MenuNavegation from "../components/MenuNavegation";
import Avatar from "vue-avatar";

export default {
    name: "ProfileUser",
    data: () => ({
        drawer: false,
        group: null
    }),
    created() {
        if (!this.loggedIn) {
            this.$router.push("/login");
        } else {
            this.$store.dispatch("user/getVideosByUser");
        }
    },
    computed: {
        user() {
            return this.$store.state.auth.user.user;
        },
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
        isLoadingVideosByUser() {
            return this.$store.state.user.status.loading;
        },
        contentVideosByUser() {
            return this.$store.state.user.videos;
        }
    },
    watch: {
        group() {
            this.drawer = false;
        }
    },
    methods: {
        logout: function() {
            this.$store.dispatch("auth/LogoutURI").then(() => {
                this.$router.push("/login");
            });
        }
    },
    components: {
        MenuNavegation,
        Avatar
    }
};
</script>

<style lang="scss" scope>
@import "../scss/_variables.scss";

.v-list-item__title {
    font-size: $__font-size-labels;
}

v-toolbar__title {
    font-size: $__font-size-labels;
}

a:link {
    text-decoration: none;
}

video {
    max-width: 100%;
    height: auto;
}

.v-navigation-drawer {
    height: 36% !important;
}
</style>
