<template>
  <v-row align="center" justify="center">
    <v-col cols="10" sm="10" md="10" lg="10" xl="10">
      <swiper class="swiper" ref="mySwiper" :options="swiperOption">
        <swiper-slide v-for="(data, index) in contentVideo" :key="index">
          <VideoPlayerDesktop
            :video="data.path_video"
            :like="data.like_video"
            :saveVideo="data.save_video"
            :key="index"
            :keyIndex="index"
            :videoId="data.id_video"
            :hashtags="data.hashtags"
            :userVideo="data.user"
            :contentComments="data.comments"
          />
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </swiper-slide>
      </swiper>
    </v-col>
  </v-row>
</template>

<script>
// import CommentService from '../services/comment.service'
import VideoPlayerDesktop from "../components/VideoPlayerDesktop";
// import videoJson from '../videoJson.json'

export default {
  name: "ListVideoDesktop",
  props: {
    contentVideo: Array
  },
  data() {
    return {
      currentSlide: false,
      swiperOption: {
        direction: "vertical",
        onSlideChangeEnd: this.changeSwiperIndex
        // slidesPerView: 1,
        // spaceBetween: 5
      }
    };
  },
  components: {
    VideoPlayerDesktop
  },
  methods: {
    changeSwiperIndex() {
      var activeIndex = this.$refs.mySwiper.$swiper.activeIndex;

      if (activeIndex !== 0) {
        var videoPrevContainer = document.querySelector(".swiper-slide-prev");
        videoPrevContainer.querySelector("video").pause();
      }

      var videoNextContainer = document.querySelector(".swiper-slide-next");
      videoNextContainer.querySelector("video").pause();

      var videoActiveContainer = document.querySelector(".swiper-slide-active");
      var videoActive = videoActiveContainer.querySelector("video");
      videoActive.currentTime = 0;
      videoActive.play();

      // if (videoActiveContainer !== null) {
      //   this.currentSlide = true
      // } else {
      //   this.currentSlide = false
      // }

      var slidesCount = document.getElementsByClassName("swiper-slide").length;
      if (activeIndex === slidesCount - 2) {
        // console.log('last slide')
        this.loadMoreVideo();
      }
      // return this.$refs.mySwiper.$swiper.activeIndex
    },
    loadMoreVideo() {
      // console.log('load more')
      this.$store.dispatch("swiperVideo/getMoreVideos", this.offset);
      this.offset += 1;
    }
  }
};
</script>

<style lang="scss" scoped>
.swiper-container {
  width: 100%;
  height: 84vh;
}

.swiper-slide {
  overflow: hidden;
}
</style>
