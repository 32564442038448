<template>
    <div>
        <v-container v-if="isLoading">
            <v-row align="center" justify="center" v-if="isMobile">
                <ListVideoMobile :contentVideo="contentVideo" />
            </v-row>
            <v-row v-else>
                <ListVideoDesktop :contentVideo="contentVideo" />
            </v-row>
            <MenuNavegation v-if="isMobile" />
        </v-container>
        <v-container v-if="!isLoading" class="isLoading">
            <v-img max-width="60%" src="index_f.png"></v-img>
        </v-container>
    </div>
</template>

<script>
import ListVideoMobile from "../components/ListVideoMobile";
import ListVideoDesktop from "../components/ListVideoDesktop";
import MenuNavegation from "../components/MenuNavegation";
import { isMobile } from "mobile-device-detect";

export default {
    name: "Home",
    data: () => ({}),
    computed: {
        isMobile() {
            return isMobile;
        },
        contentVideo() {
            return this.$store.state.swiperVideo.videos;
        },
        isLoading() {
            return this.$store.state.swiperVideo.status.loading;
        }
    },
    created() {
        this.$store.dispatch("swiperVideo/getVideos");
    },
    components: {
        ListVideoMobile,
        ListVideoDesktop,
        MenuNavegation
    }
};
</script>

<style>
body {
    background-color: rgb(244, 244, 244) !important;
}

.isLoading {
    background: rgb(177, 97, 167);
    background: linear-gradient(
        180deg,
        rgba(177, 97, 167, 1) 0%,
        rgba(147, 73, 154, 1) 25%,
        rgba(108, 43, 136, 1) 50%,
        rgba(63, 15, 119, 1) 75%,
        rgba(0, 3, 108, 1) 100%
    );
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

/*.v-bottom-navigation {
  background-color: rgba(0, 0, 0, 0) !important;
  color: #fff !important;
}

.v-bottom-navigation .v-btn {
  color: #fff !important;
}

.v-bottom-navigation .v-btn--active {
  color: rgb(98, 0, 234) !important;
}*/
</style>
