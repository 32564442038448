<template>
  <swiper class="swiper"
  :options="swiperOption" ref="mySwiper">
    <swiper-slide v-for="(data, index) in contentVideo" :key="index">
      <VideoPlayerMobile
        :video="data.path_video"
        :like="data.like_video"
        :saveVideo="data.save_video"
        :key="index"
        :keyIndex="index"
        :videoId="data.id_video"
        :hashtags="data.hashtags"
        :userVideo="data.user"
      />
    </swiper-slide>
  </swiper>
</template>

<script>
// import CommentService from '../services/comment.service'
import VideoPlayerMobile from '../components/VideoPlayerMobile'
// import videoJson from '../videoJson.json'

export default {
  name: 'ListVideoBookmark',
  props: {
    contentVideo: Array
  },
  data () {
    return {
      swiperOption: {
        direction: 'vertical',
        onSlideChangeEnd: this.changeSwiperIndex
        // slidesPerView: 1,
        // spaceBetween: 5
      }
    }
  },
  components: {
    VideoPlayerMobile
  },

  methods: {
    changeSwiperIndex () {
      var activeIndex = this.$refs.mySwiper.$swiper.activeIndex
      var slidesCount = document.getElementsByClassName('swiper-slide').length

      if (activeIndex !== 0) {
        var videoPrevContainer = document.querySelector('.swiper-slide-prev')
        videoPrevContainer.querySelector('video').pause()
      }

      if (activeIndex !== slidesCount - 1) {
        var videoNextContainer = document.querySelector('.swiper-slide-next')
        videoNextContainer.querySelector('video').pause()
      }

      var videoActiveContainer = document.querySelector('.swiper-slide-active')
      var videoActive = videoActiveContainer.querySelector('video')
      videoActive.currentTime = 0
      videoActive.play()

      if (activeIndex === slidesCount - 2) {
        // TODO: Agregar llamado a servicio para obtener mas videos Bookmark

        this.loadMoreVideo()
      }
      // return this.$refs.mySwiper.$swiper.activeIndex
    },
    loadMoreVideo () {
      // console.log('load more')
      this.$store.dispatch('swiperVideo/getMoreVideos', this.amountVideos)
      this.amountVideos += 1
    }
  }
}
</script>

<style lang="scss" scoped>
.swiper-container {
  width: 100%;
  height: 100vh;
}

.swiper-slide {
  overflow: hidden;
}
</style>
