<template>
  <v-container>
    <v-row
      align="center"
      justify="center"
      v-if="isMobile"
    >
      <v-img
        src="error_404.jpg"
      ></v-img>
    </v-row>
  </v-container>
</template>

<script>
import { isMobile } from 'mobile-device-detect'

export default {
  name: 'ErrorPage',
  data: () => (
    {
      // drawer: false,
      // group: null,
      // value: 0,
      // btnColorIconNavigation: '#6200ea'
    }
  ),
  computed: {
    isMobile () {
      return isMobile
    }
  }
}
</script>

<style lang="scss">

</style>
