<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
      :content-class="'scrollComments' + videoId"
    >
      <v-card tile>
        <v-btn
          icon
          dark
          right
          absolute
          style="z-index: 9999"
          class="my-3"
          @click="closeCommmentsDialog"
        >
          <v-icon color="black">fa-times</v-icon>
        </v-btn>

        <v-card-text class="pa-0 py-12 content-comments">
          <v-list
            v-for="(dataComment, indexComment) in contentComments"
            :key="indexComment"
            three-line
            subheader
          >
            <v-container>
              <v-row>
                <v-col class="pa-0" cols="11">
                  <v-list-item class="pa-0">
                    <v-list-item-avatar>
                      <avatar
                        :username="
                          dataComment.user.name ? dataComment.user.name : ''
                        "
                        background-color="#FFC107"
                        :size="45"
                        color="#fff"
                      >
                      </avatar>
                    </v-list-item-avatar>
                    <p>
                      <b>{{ dataComment.user.name }}</b
                      ><br />
                      {{ dataComment.comment }}
                    </p>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-list>

          <!-- <v-container> -->
          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-form name="form" @submit.prevent="handleComment(videoId)">
                <v-container class="comments_input_video">
                  <v-row align="center">
                    <v-col cols="10" sm="10">
                      <v-text-field
                        v-model="comment.comment"
                        label="Añadir comentario..."
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2" sm="2">
                      <button class="btn btn-primary" :disabled="loading">
                        <span
                          v-show="loading"
                          class="spinner-border spinner-border-sm"
                        ></span>
                        <v-icon>fa-paper-plane</v-icon>
                      </button>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-col>
          </v-row>
          <!-- </v-container> -->
        </v-card-text>

        <!-- comments -->
        <v-skeleton-loader
          v-if="!isLoadingComments"
          width="100%"
          type="list-item-avatar-three-line,
            list-item-avatar-three-line,
            list-item-avatar-three-line,
            list-item-avatar-three-line,
            list-item-avatar-three-line,
            list-item-avatar-three-line,
            list-item-avatar-three-line"
        ></v-skeleton-loader>

        <div style="flex: 1 1 auto"></div>
      </v-card>
    </v-dialog>

    <div class="hashtags">
      <h4>@{{ userVideo.name }}</h4>
      <div class="text-overflow-handle">
        <span
          v-for="(category, indexCategory) in categories"
          :key="indexCategory"
          >{{ category.name_category }}
        </span>
      </div>
      <div>
        <router-link
          v-for="(dataHashtag, index) in hashtags"
          :key="index"
          :to="{
            path: 'video-by-hashtag',
            query: { hashtag: dataHashtag.hashtag }
          }"
        >
          <!-- <span
                        v-if="hashtag == dataHashtag.hashtag"
                        class="hashtag-active"
                        >#{{ dataHashtag.hashtag }}
                    </span> -->
          <span>#{{ dataHashtag.hashtag }} </span>
        </router-link>
      </div>
    </div>
    <video
      preload="metadata"
      loop
      class=""
      playsinline="true"
      x5-playsinline="true"
      webkit-playsinline="true"
      :tabindex="keyIndex"
      mediatype="video"
      :src="video"
    ></video>

    <v-btn
      icon
      fab
      right
      absolute
      class="btnProfileVideo"
      :to="{
        path: 'profile-user-visited',
        query: { userVisitedId: userVideo.id }
      }"
    >
      <avatar
        :username="userVideo.name"
        background-color="#FFC107"
        :size="45"
        color="#fff"
      >
      </avatar>
    </v-btn>

    <v-btn
      icon
      fab
      right
      absolute
      class="btnFollowUser"
      @click="followUser(userVideo.id, !followerUser, keyIndex)"
    >
      <v-icon v-if="followerUser === true" :color="btnColorActive" size="26"
        >fa-share</v-icon
      >
      <v-icon v-if="followerUser === false" :color="btnColor" size="26"
        >fa-share</v-icon
      >
    </v-btn>
    <v-btn
      icon
      fab
      right
      absolute
      class="like"
      @click="likeVideo(videoId, !like, keyIndex)"
    >
      <v-icon v-if="like === true" :color="btnColorActive" size="22"
        >fa-heart</v-icon
      >
      <v-icon v-if="like === false || like === null" :color="btnColor" size="22"
        >fa-heart</v-icon
      >
    </v-btn>

    <v-btn
      icon
      fab
      right
      absolute
      class="comment"
      @click="getComments(videoId, (dialog = true))"
    >
      <v-icon :color="btnColor" size="22">fa-comment</v-icon>
    </v-btn>
  </div>
</template>

<script>
import Comment from "../models/comment";
import Avatar from "vue-avatar";

export default {
  name: "VideoPlayerMobile",
  props: {
    video: String,
    like: Boolean,
    saveVideo: Boolean,
    followerUser: Boolean,
    videoId: Number,
    keyIndex: Number,
    hashtags: Array,
    categories: Array,
    userVideo: Object
  },
  data: () => ({
    btnColor: "#fff",
    btnColorActive: "#6200ea",
    loading: false,
    dialog: false,
    comment: new Comment("", "")
  }),
  computed: {
    contentComments() {
      return this.$store.state.comments.comments;
    },
    isLoadingComments() {
      return this.$store.state.comments.status.loading;
    },
    hashtag() {
      return this.$route.query.hashtag;
    }
  },
  methods: {
    followUser(userId, followerUser, keyIndex) {
      this.$store.dispatch("swiperVideo/postFollowUser", {
        userId,
        followerUser,
        keyIndex
      });
    },
    btnSaveVideoLike: function(videoId, saveVideo, keyIndex) {
      this.$store.dispatch("swiperVideo/postLikeVideoSave", {
        videoId,
        saveVideo,
        keyIndex
      });
    },
    likeVideo: function(videoId, likeVideo, keyIndex) {
      this.$store.dispatch("swiperVideo/postLikeVideo", {
        videoId,
        likeVideo,
        keyIndex
      });
    },
    getComments: function(videoId, dialog) {
      var videoActiveContainer = document.querySelector(".swiper-slide-active");
      var videoActive = videoActiveContainer.querySelector("video");
      videoActive.pause();

      this.dialog = dialog;
      this.$store.dispatch("comments/getComments", { videoId });
    },
    closeCommmentsDialog: function() {
      this.dialog = false;
      var videoActiveContainer = document.querySelector(".swiper-slide-active");
      var videoActive = videoActiveContainer.querySelector("video");
      videoActive.play();
    },
    handleComment(videoId) {
      // console.log('Hola')

      this.$store.dispatch("comments/postComment", {
        comment: this.comment,
        videoId
      });
      this.comment.comment = "";
    }
  },
  components: {
    Avatar
  }
};
</script>

<style lang="scss" scope>
@import "../scss/_variables.scss";
/*
video {
  aspect-ratio: 400/600;
  width: 100%;
  height: auto;
  position: relative;
}*/
p {
  overflow: hidden;
}
.swiper-container {
  width: 100%;
  height: 100vh;
}

.swiper-slide {
  overflow: hidden;
}

.swiper-wrapper video {
  // margin-left: calc((100vw - (100vh * 1.7)) / 2);
  // margin-right: calc((100vw - (100vh * 1.7)) / 2);
  width: 100%;
  height: 100vh;
  object-fit: fill;
  top: 0;
  left: 0;
}

@media (min-aspect-ratio: 16/9) {
  .swiper-wrapper video {
    margin-left: 0;
  }
}

// .swiper-wrapper video {
//     z-index: 2;
//     position: absolute;
//     // height: auto;
//     width: 100%;
//     height: 100vh;
//     object-fit: fill;
//     top: 0;
//     left: 0;
// }

body::-webkit-scrollbar {
  display: none;
}

.text-overflow-handle {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.hashtags a {
  text-decoration: none !important;
  color: #fff !important;
}

.hashtag-active {
  font-weight: bold;
  font-size: 1.1em;
  color: #6200ea;
}

.hashtags {
  z-index: 3;
  position: absolute;
  bottom: 21%;
  // margin-top: 110%;
  left: 4px;
  font-size: 0.8rem;
  /*color: rgb(233, 207, 232);*/
  color: #fff;
  width: 78%;
  background-color: rgba(32, 0, 0, 0.1);
  padding: 9px;
  -webkit-box-shadow: 12px 29px 81px 0px rgba(32, 0, 0, 0.2);
  -moz-box-shadow: 12px 29px 81px 0px rgba(32, 0, 0, 0.2);
  box-shadow: 0 0 50px rgba(32, 0, 0, 0.2);

  /*height: 90%;
  padding-top: 100%;*/
}

// @media (min-aspect-ratio: 16/9) {
//   video {
//     margin-left: 0;
//   }
// }

.btnProfileVideo {
  z-index: 3;
  position: absolute;
  bottom: 42%;
}

.btnFollowUser {
  z-index: 3;
  position: absolute;
  bottom: 34%;
}

.like {
  z-index: 3;
  position: absolute;
  bottom: 27%;
}

.comment {
  z-index: 3;
  position: absolute;
  bottom: 21%;
}

.content-comments {
  margin-bottom: 12%;
}

.comments_input_video {
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: #fff;
  padding-top: 2px !important;
  padding-bottom: 0px !important;
}
</style>
