<template>
    <v-container>
        <v-row>
            <v-col class="pa-0" cols="12" sm="12" md="12" lg="12" xl="12">
                <v-app-bar
                    dark
                    height="110"
                    style="background-image: radial-gradient(circle at 120.71% 50%, #b161a7 0, #93499a 25%, #6c2b88 50%, #3f0f77 75%, #00036c 100%);"
                >
                    <h3>Sube tu video</h3>
                </v-app-bar>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                <upload-files></upload-files>
            </v-col>
        </v-row>
        <MenuNavegation v-if="isMobile" />
        <MenuDesktop v-if="!isMobile" />
    </v-container>
</template>

<script>
import MenuNavegation from "../components/MenuNavegation";
import MenuDesktop from "../components/MenuDesktop";
import UploadFiles from "../components/UploadFiles";
import { isMobile } from "mobile-device-detect";

export default {
    name: "UploadVideo",
    data: () => ({}),
    created() {
        if (!this.loggedIn) {
            this.$router.push("/login");
        }
    },
    computed: {
        isMobile() {
            return isMobile;
        },
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        }
    },
    components: {
        MenuNavegation,
        UploadFiles,
        MenuDesktop
    }
};
</script>

<style lang="scss"></style>
