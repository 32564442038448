<template>
    <div>
        <div v-if="currentFile">
            <div>
                <v-progress-linear
                    v-model="progress"
                    color="light-blue"
                    height="25"
                    reactive
                >
                    <strong>{{ progress }} %</strong>
                </v-progress-linear>
            </div>
        </div>

        <v-row no-gutters justify="center" align="center">
            <v-col cols="12" class="mt-2">
                <vue-tags-input
                    v-model="tag"
                    :tags="tags"
                    :validation="validation"
                    @tags-changed="newTags => (tags = newTags)"
                />
            </v-col>
            <input type="hidden" name="tags" :value="tags" />

            <v-col cols="12" class="mt-5">
                <v-file-input
                    show-size
                    label="Adjuntar video"
                    @change="selectFile"
                ></v-file-input>
            </v-col>

            <v-col class="text-center mt-5">
                <v-btn color="primary" dark @click="upload">
                    Subir
                    <!-- <v-icon right dark>fa-cloud-upload</v-icon> -->
                </v-btn>
            </v-col>
        </v-row>

        <v-alert v-if="message" border="left" color="blue-grey" dark>
            {{ message }}
        </v-alert>
    </div>
</template>

<script>
import { isMobile } from "mobile-device-detect";
import VueTagsInput from "@johmun/vue-tags-input";
import axios from "axios";

export default {
    name: "upload-files",
    data: () => ({
        currentFile: undefined,
        fileInfos: [],
        // tags input
        tag: "",
        tags: [],
        autocompleteItems: [],
        debounce: null,
        validation: [
            {
                classes: "no-braces",
                rule: ({ text }) => text.indexOf(" ") !== -1
            }
        ]
    }),
    /*watch: {
    'tag': 'initItems'
  },*/
    methods: {
        selectFile(file) {
            this.progress = 0;
            this.currentFile = file;
        },
        upload() {
            if (!this.currentFile) {
                this.message = "Selecciona un archivo!";
                return;
            }
            // this.message = ''
            //console.dir(this.tags);
            //var arrayTags = JS
            this.$store
                .dispatch("uploadFile/postVideoUpload", {
                    file: this.currentFile,
                    tags: this.tags
                })
                .then(
                    () => {
                        this.$router.push("/profile");
                    },
                    error => {
                        this.loading = false;
                        this.message =
                            (error.response && error.response.data) ||
                            error.message ||
                            error.toString();
                    }
                );

            // UploadService.upload(this.currentFile, (event) => {
            //   this.progress = Math.round((100 * event.loaded) / event.total)
            // })
            //   .then((response) => {
            //     this.message = response.data.message
            //     return UploadService.getFiles()
            //   })
            //   .then((files) => {
            //     this.fileInfos = files.data
            //   })
            //   .catch(() => {
            //     this.progress = 0
            //     this.message = 'Could not upload the file!'
            //     this.currentFile = undefined
            //   })
        },

        update(newTags) {
            this.autocompleteItems = [];
            this.tags = newTags;
        }
    },
    components: {
        VueTagsInput
    },
    computed: {
        isMobile() {
            return isMobile;
        },
        isLoggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
        progress() {
            return this.$store.state.uploadFile.status.progress;
        },
        message() {
            return this.$store.state.uploadFile.status.progress;
        }
    }
};
</script>

<style lang="scss"></style>
