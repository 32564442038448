<template>
    <v-container>
        <v-row>
            <v-col class="pa-0" xs="12" sm="12" md="12" lg="12" xl="10">
                <v-card class="mx-auto overflow-hidden" flat height="100%">
                    <v-app-bar
                        dark
                        height="110"
                        style="background-image: radial-gradient(circle at 120.71% 50%, #b161a7 0, #93499a 25%, #6c2b88 50%, #3f0f77 75%, #00036c 100%);"
                    >
                        <v-toolbar-title size="15" v-if="userProfile.name">
                            <p>{{ userProfile.name }}</p>
                            <v-btn
                                color="blue"
                                form="check-login-form"
                                v-if="userProfile.follow_user == false"
                                @click="
                                    followUser(
                                        userProfile.id,
                                        !userProfile.follow_user
                                    )
                                "
                            >
                                seguir
                            </v-btn>

                            <v-btn
                                color="blue"
                                form="check-login-form"
                                v-if="userProfile.follow_user == true"
                                @click="
                                    followUser(
                                        userProfile.id,
                                        !userProfile.follow_user
                                    )
                                "
                            >
                                dejar de seguir
                            </v-btn>
                        </v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn icon class="pr-4">
                            <avatar
                                :username="
                                    userProfile.name ? userProfile.name : 'OV'
                                "
                                background-color="#FFC107"
                                :size="50"
                                color="#fff"
                            >
                            </avatar>
                        </v-btn>
                    </v-app-bar>

                    <v-card-text>
                        <v-row>
                            <v-col
                                cols="4"
                                sm="4"
                                md="4"
                                lg="4"
                                xl="4"
                                v-for="(data, index) in contentVideosByUser"
                                :key="index"
                            >
                                <video
                                    :key="index"
                                    :src="data.path_video"
                                    preload="metadata"
                                />
                            </v-col>
                        </v-row>

                        <v-row v-if="!isLoadingVideosByUser">
                            <v-col>
                                <v-skeleton-loader
                                    width="100%"
                                    type="image, list-item-three-line"
                                ></v-skeleton-loader>
                            </v-col>
                            <v-col>
                                <v-skeleton-loader
                                    width="100%"
                                    type="image, list-item-three-line"
                                ></v-skeleton-loader>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <MenuNavegation />
    </v-container>
</template>

<script>
import MenuNavegation from "../components/MenuNavegation";
import Avatar from "vue-avatar";

export default {
    name: "ProfileUserVisited",
    data: () => ({
        drawer: false,
        group: null
    }),
    created() {
        if (!this.loggedIn) {
            this.$router.push("/login");
        } else {
            this.$store.dispatch("user/getUserProfile", this.userVisitedId);
            this.$store.dispatch("user/getVideosByUser", this.userVisitedId);
        }
    },
    mounted() {},
    computed: {
        userProfile() {
            return this.$store.state.user.userProfile;
        },
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
        isLoadingVideosByUser() {
            return this.$store.state.user.status.loading;
        },
        contentVideosByUser() {
            return this.$store.state.user.videos;
        },
        userVisitedId() {
            return this.$route.query.userVisitedId;
        }
    },
    watch: {
        group() {
            this.drawer = false;
        }
    },
    methods: {
        followUser(userId, actionFollower) {
            console.log("Seguir");
            this.$store.dispatch("user/postFollowUser", {
                userId,
                actionFollower
            });
        },
        logout: function() {
            this.$store.dispatch("auth/LogoutURI").then(() => {
                this.$router.push("/login");
            });
        }
    },
    components: {
        MenuNavegation,
        Avatar
    }
};
</script>

<style lang="scss" scope>
@import "../scss/_variables.scss";

.v-list-item__title {
    font-size: $__font-size-labels;
}

v-toolbar__title {
    font-size: $__font-size-labels;
}

a:link {
    text-decoration: none;
}

video {
    max-width: 100%;
    height: auto;
}

.v-navigation-drawer {
    height: 36% !important;
}
</style>
