var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"xs":"12","sm":"12","md":"12","lg":"12","xl":"10"}},[_c('v-app-bar',{staticStyle:{"background-image":"radial-gradient(circle at 120.71% 50%, #b161a7 0, #93499a 25%, #6c2b88 50%, #3f0f77 75%, #00036c 100%)"},attrs:{"dark":"","height":"110"}},[_c('h3',[_vm._v("Registrar usuario")])])],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"pt-7",attrs:{"xs":"10","sm":"10","md":"10","lg":"10"}},[(_vm.messageRegisterError)?_c('v-alert',{attrs:{"border":"right","colored-border":"","type":"error","elevation":"2"}},[_vm._v(" "+_vm._s(_vm.messageRegisterError)+" ")]):_vm._e(),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Nombre","required":""},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"phone","rules":{
              required: true
              // regex: '^(71|72|74|76|81|82|84|85|86|87|88|89)\\d{5}$'
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Teléfono","required":""},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"E-mail","required":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"label":"Contraseña"},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})]}}],null,true)}),_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"color":"primary","type":"submit","disabled":invalid}},[_vm._v(" Registrarse ")]),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"default","form":"check-login-form","to":"/"}},[_vm._v("Cancelar")])],1),_c('div',{staticClass:"text-center pt-10"},[_c('router-link',{attrs:{"to":"/terms-and-conditions"}},[_vm._v(" Términos y Condiciones ")])],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }