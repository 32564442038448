<template>
  <v-container>
    <v-row>
      <v-col class="pa-0" xs="12" sm="12" md="12" lg="12" xl="10">
        <v-app-bar
          dark
          height="110"
          style="background-image: radial-gradient(circle at 120.71% 50%, #b161a7 0, #93499a 25%, #6c2b88 50%, #3f0f77 75%, #00036c 100%);"
        >
          <h3>Registrar usuario</h3>
        </v-app-bar>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <v-col class="pt-7" xs="10" sm="10" md="10" lg="10">
        <v-alert
          v-if="messageRegisterError"
          border="right"
          colored-border
          type="error"
          elevation="2"
        >
          {{ messageRegisterError }}
        </v-alert>
        <validation-observer ref="observer" v-slot="{ invalid }">
          <form @submit.prevent="handleSubmit">
            <validation-provider
              v-slot="{ errors }"
              name="Name"
              rules="required"
            >
              <v-text-field
                v-model="user.name"
                :error-messages="errors"
                label="Nombre"
                required
              ></v-text-field>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="phone"
              :rules="{
                required: true
                // regex: '^(71|72|74|76|81|82|84|85|86|87|88|89)\\d{5}$'
              }"
            >
              <v-text-field
                v-model="user.phone"
                :error-messages="errors"
                label="Teléfono"
                required
              ></v-text-field>
            </validation-provider>
            <validation-provider v-slot="{ errors }" name="email" rules="email">
              <v-text-field
                v-model="user.email"
                :error-messages="errors"
                label="E-mail"
                required
              ></v-text-field>
            </validation-provider>
            <validation-provider v-slot="{ errors }" name="password">
              <v-text-field
                v-model="user.password"
                :error-messages="errors"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                label="Contraseña"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </validation-provider>
            <div class="text-center">
              <v-btn color="primary" type="submit" :disabled="invalid">
                Registrarse
              </v-btn>
              <!-- </div>

            <div class="text-center"> -->
              <v-btn class="ml-3" color="default" form="check-login-form" to="/"
                >Cancelar</v-btn
              >
            </div>
            <!-- <v-btn class="mr-4" @click="clear">
              Limpiar
            </v-btn> -->
            <!-- Terminos y condiciones -->
            <div class="text-center pt-10">
              <router-link to="/terms-and-conditions">
                Términos y Condiciones
              </router-link>
            </div>
          </form>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import User from "../models/user";
import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from "vee-validate";

setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})"
});

extend("required", {
  ...required,
  message: "{_field_} can not be empty"
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters"
});

extend("regex", {
  ...regex,
  message: "{_field_} {_value_} does not match {regex}"
});

extend("email", {
  ...email,
  message: "Email must be valid"
});
export default {
  name: "Register",
  data() {
    return {
      user: new User(),
      name: "",
      phone: "",
      email: "",
      showPassword: false
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
     messageRegisterError() {
      return this.$store.state.auth.messageRegisterError;
    },
  },
  mounted() {
    // if (this.loggedIn) {
    //   // this.$router.push('/profile')
    // }
  },
  methods: {
    handleSubmit() {
      if (this.$refs.observer.validate()) {
        this.loading = true;
        // this.$validator.validateAll().then(isValid => {
        //   if (!isValid) {
        //     this.loading = false
        //     return
        //   }
        this.$store.dispatch("auth/register", this.user).then(
          () => {
            this.$router.push("/profile");
          },
          error => {
            this.loading = false;
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
    clear() {
      this.name = "";
      this.phone = "";
      this.email = "";
      this.select = null;
      this.checkbox = null;
      this.$refs.observer.reset();
    }
    // handleRegister () {
    //   this.message = ''
    //   this.submitted = true
    //   this.$store.dispatch('auth/register', this.user).then(
    //     data => {
    //       this.message = data.message
    //       this.successful = true
    //     },
    //     error => {
    //       this.message =
    //             (error.response && error.response.data) ||
    //             error.message ||
    //             error.toString()
    //       this.successful = false
    //     }
    //   )
    // this.$validator.validate().then(isValid => {
    //   if (isValid) {
    //     this.$store.dispatch('auth/register', this.user).then(
    //       data => {
    //         this.message = data.message
    //         this.successful = true
    //       },
    //       error => {
    //         this.message =
    //           (error.response && error.response.data) ||
    //           error.message ||
    //           error.toString()
    //         this.successful = false
    //       }
    //     )
    //   }
    // })
    // }
  }
};
</script>
