<template>
    <v-row :key="keyIndex">
        <v-col sm="4" md="4" lg="4" xl="4">
            <div class="video-container">
                <video
                    class="video"
                    preload="metadata"
                    v-if="keyIndex == 0"
                    autoplay="autoplay"
                    loop
                >
                    <source :src="video" type="video/mp4" />
                </video>
                <video class="video" preload="metadata" v-else loop>
                    <source :src="video" type="video/mp4" />
                </video>
            </div>
        </v-col>
        <v-col sm="6" md="6" lg="6" xl="6">
            <v-list
                v-for="(dataComment, indexComment) in contentComments"
                :key="indexComment + '-' + keyIndex"
            >
                <v-list-item class="pa-0">
                    <v-list-item-avatar>
                        <v-img
                            src="https://cdn.vuetifyjs.com/images/john.png"
                        ></v-img>
                    </v-list-item-avatar>
                    <p>
                        <b>{{ dataComment.user.name }}</b
                        ><br />
                        {{ dataComment.comment }}
                    </p>
                </v-list-item>
            </v-list>

            <v-col cols="12">
                <v-form name="form" @submit.prevent="handleComment(videoId)">
                    <v-text-field
                        v-model="comment.comment"
                        label="Añadir comentario..."
                        required
                    ></v-text-field>
                    <button
                        class="btn btn-primary btn-block"
                        :disabled="loading"
                        hidden
                    >
                        <span
                            v-show="loading"
                            class="spinner-border spinner-border-sm"
                        ></span>
                        <span>Enviar</span>
                    </button>
                </v-form>
            </v-col>
        </v-col>
    </v-row>
</template>

<script>
import Comment from "../models/comment";
// import CommentService from '../services/comment.service'

export default {
    name: "VideoPlayerDesktop",
    props: {
        video: String,
        like: Boolean,
        saveVideo: Boolean,
        videoId: Number,
        keyIndex: Number,
        hashtags: Array,
        userVideo: Object,
        contentComments: Array
    },
    data: () => ({
        btnColor: "#e9cfe8",
        btnColorActive: "#6200ea",
        btnSaveVideo: "#e9cfe8",
        btnSaveVideoActive: "#6200ea",
        btnMessage: "#6200ea",
        loading: false,
        dialog: false,
        comment: new Comment("", "")
    }),
    // computed: {
    //   contentComments () {
    //     return this.$store.state.comments.comments
    //   }
    // },
    // created: function () {
    //   var vm = this
    //   this.$store.dispatch('comments/getComments', { videoId: vm.videoId })
    // },
    methods: {
        btnSaveVideoLike: function(videoId, saveVideo) {
            this.$store.dispatch("swiperVideo/postLikeVideoSave", {
                videoId,
                saveVideo,
                pathName: this.$route.name
            });
        },
        likeVideo: function(videoId, likeVideo) {
            // console.log(videoId, likeVideo)
            this.$store.dispatch("swiperVideo/postLikeVideo", {
                videoId,
                likeVideo
            });
        },
        // getComments: function (videoId, dialog) {
        //   this.$store.dispatch('comments/getComments', videoId)
        // },
        handleComment(videoId) {
            // console.log('Hola')
            this.$store.dispatch("comments/postComment", {
                comment: this.comment,
                videoId
            });
            this.comment.comment = "";
            // document.getElementByClass('scrollComments').scrollTop = 0
            // CommentService.postComment(this.comment, videoId).then(
            //   response => {
            //     // console.log(response.data.data)
            //     // this.contentComments = response.data.data
            //   },
            //   error => {
            //     this.contentVideo =
            //     (error.response && error.response.data) ||
            //     error.message ||
            //     error.toString()
            //   }
            // )
        }
    }
};
</script>

<style lang="scss" scoped>
// .video-container {
//     /* width is set as 100% here. any width can be specified as per requirement */
//     width: 100%;
//     padding-top: 100%;
//     height: 0px;
//     position: relative;
// }

// .video {
//     width: 100%;
//     height: 100%;
//     position: absolute;
//     top: 0;
//     left: 0;
// }
</style>
