<template>
  <v-card  class="trendings">
    <v-card-text rounded="false">
      <v-item-group multiple>
        <v-subheader id="t_title">Tendencias</v-subheader>
        <v-item
          class="trend"
          v-for="(data, index) in hashtagTrandings"
          :key="index"
        >
          <v-chip
            active-class="purple--tex"
          >
            #{{ data.hashtag }}
          </v-chip>
        </v-item>
      </v-item-group>
    </v-card-text>

    <v-divider></v-divider>

  </v-card>
</template>

<script>

export default {
  name: 'HashtagTrendings',
  props: {
    hashtagTrandings: Array,
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
#t_title{
  color: #fff;
  font-size: 15px;
  padding-left: 0;
  padding-top: 0;
}
.trendings {
  border-radius: 0 !important;
  background-image: radial-gradient(circle at 120.71% 50%, rgb(177, 97, 167) 0px, rgb(147, 73, 154) 25%, rgb(108, 43, 136) 50%, rgb(63, 15, 119) 75%, rgb(0, 3, 108) 100%);
}
.trendings .v-card__text {
  padding-top: 5px;
  padding-bottom: 10px;
}
.trend{
  background-color: transparent !important;
  color: white !important;
  border: 1px solid !important;
  margin-right: 7px;
  margin-top: 10px;

}
</style>
