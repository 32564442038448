<template>
    <v-container>
        <v-row align="center" justify="center">
            <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                xl="2"
                v-for="(game, index) in games"
                :key="index"
            >
                <router-link
                    :key="index"
                    :to="{ path: 'game-show', query: { gameId: game.id } }"
                >
                    <v-card color="#385F73" dark>
                        <v-img :src="game.path_image" aspect-ratio="3"></v-img>
                        <v-card-title>
                            <div class="headline">{{ game.name_game }}</div>
                        </v-card-title>
                    </v-card>
                </router-link>
            </v-col>
        </v-row>
        <MenuNavegation v-if="isMobile" />
    </v-container>
</template>

<script>
import { isMobile } from "mobile-device-detect";
import MenuNavegation from "../components/MenuNavegation";

export default {
    name: "Games",
    data: () => ({
        // drawer: false,
        // group: null,
        // value: 0,
        // btnColorIconNavigation: '#6200ea'
    }),
    computed: {
        isMobile() {
            return isMobile;
        },
        games() {
            return this.$store.state.game.games;
        }
    },
    created() {
        this.$store.dispatch("game/getGames");
    },
    components: {
        MenuNavegation
    }
};
</script>

<style lang="scss">
a:link {
    style: none;
}
</style>
