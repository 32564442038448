<template>
  <iframe
    :src="game.path_game"
    width="100%"
    height="100%"
    frameborder="0"
    style="border:0"
    :allowfullscreen="true"
    :allow="'allow-scripts allow-same-origin'"
    :sandbox="'allow-scripts allow-same-origin'"
  ></iframe>
  <!-- <MenuNavegation v-if="isMobile" /> -->
</template>

<script>
import { isMobile } from "mobile-device-detect";
// import MenuNavegation from '../components/MenuNavegation'

export default {
  name: "GameFullScreen",
  data: () => ({
    // drawer: false,
    // group: null,
    // value: 0,
    // btnColorIconNavigation: '#6200ea'
  }),
  computed: {
    isMobile() {
      return isMobile;
    },
    gameId() {
      return this.$route.query.gameId;
    },
    game() {
      return this.$store.state.game.game;
    }
  },
  mounted() {
    this.$store.dispatch("game/getGameShow", this.gameId);
  },
  components: {
    // MenuNavegation
  }
};
</script>

<style lang="scss"></style>
