<template>
  <v-container>
    <v-row>
      <v-col class="pa-0" xs="12" sm="12" md="12" lg="12" xl="10">
        <v-app-bar
          dark
          height="110"
          style="background-image: radial-gradient(circle at 120.71% 50%, #b161a7 0, #93499a 25%, #6c2b88 50%, #3f0f77 75%, #00036c 100%);"
        >
          <h3>Actualizar Usuario</h3>
        </v-app-bar>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <v-col class="pt-7" xs="10" sm="10" md="10" lg="10">
        <v-alert
          v-if="messageUpdateError"
          border="right"
          colored-border
          type="error"
          elevation="2"
        >
          {{ messageUpdateError }}
        </v-alert>
        <validation-observer ref="observer" v-slot="{ invalid }">
          <form @submit.prevent="handleSubmit">
            <validation-provider
              v-slot="{ errors }"
              name="birthdate"
              rules="date_format:mm/dd/yyyy"
            >
              <v-text-field
                v-model="user.birthdate"
                label="Fecha Nacimiento"
                type="date"
              ></v-text-field>
            </validation-provider>
            <validation-provider v-slot="{ errors }" name="Name">
              <v-text-field
                v-model="user.name"
                :error-messages="errors"
                label="Nombre"
              ></v-text-field>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="phone"
              rules="digits:10"
            >
              <v-text-field
                v-model="user.phone"
                :error-messages="errors"
                label="Teléfono"
              ></v-text-field>
            </validation-provider>
            <validation-provider v-slot="{ errors }" name="email" rules="email">
              <v-text-field
                v-model="user.email"
                :error-messages="errors"
                label="E-mail"
              ></v-text-field>
            </validation-provider>
            <!-- <validation-provider
                            v-slot="{ errors }"
                            name="password"
                        >
                            <v-text-field
                                v-model="user.password"
                                :error-messages="errors"
                                :append-icon="
                                    showPassword ? 'mdi-eye' : 'mdi-eye-off'
                                "
                                :type="showPassword ? 'text' : 'password'"
                                label="Contraseña"
                                @click:append="showPassword = !showPassword"
                            ></v-text-field>
                        </validation-provider>
                        <validation-provider
                            v-slot="{ errors }"
                            name="password_confirmation"
                        >
                            <v-text-field
                                v-model="user.password_confirmation"
                                :error-messages="errors"
                                :append-icon="
                                    showPassword ? 'mdi-eye' : 'mdi-eye-off'
                                "
                                :type="showPassword ? 'text' : 'password'"
                                label="Confirmar contraseña"
                                @click:append="showPassword = !showPassword"
                            ></v-text-field>
                        </validation-provider> -->
            <div class="text-center">
              <v-btn color="primary" type="submit" :disabled="invalid">
                Actualizar
              </v-btn>
              <v-btn class="ml-3" color="default" to="/profile">Cancelar</v-btn>
            </div>
          </form>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import User from "../models/user";
import {
  required,
  digits,
  email,
  max,
  min,
  regex
} from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from "vee-validate";

setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})"
});

extend("required", {
  ...required,
  message: "{_field_} can not be empty"
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters"
});
extend("min", {
  ...min,
  message: "{_field_} may not be lower than {length} characters"
});

extend("regex", {
  ...regex,
  message: "{_field_} {_value_} does not match {regex}"
});

extend("email", {
  ...email,
  message: "Email must be valid"
});
export default {
  name: "EditUser",
  data() {
    return {
      user: new User(),
      name: "",
      birthdate: "",
      phone: "",
      email: "",
      showPassword: false,
      activePicker: null,
      date: null,
      menu: false
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    messageUpdateError() {
      return this.$store.state.auth.messageUpdateError;
    }
  },
  created() {
    if (!this.loggedIn) {
      this.$router.push("/login");
    }
  },
  methods: {
    handleSubmit() {
      if (this.$refs.observer.validate()) {
        // this.loading = true;
        //this.$validator.validateAll().then(isValid => {
        //if (!isValid) {
        //this.loading = false
        // return
        // }} );

        this.$store.dispatch("auth/update", this.user).then(
          data => {
            console.log("this is the data " + JSON.stringify(data));
            this.$router.push("/profile");
          },
          error => {
            // this.loading = false;
            // this.message =
            //     (error.response && error.response.data) ||
            //     error.message ||
            //     error.toString();
          }
        );
      }
    }
  }
};
</script>
